import { ReactNode } from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';

import logo from '@public/img/logo/sn-logo-black-side@3x.png';
import cancelCross from '@public/img/icons/cancel-cross.png';

export const LoginWrapper = ({
  children,
  isRestarterFlow,
}: {
  children: ReactNode;
  isRestarterFlow: boolean | undefined;
}): JSX.Element => (
  <div
    className={`mx-[28px] flex max-w-[615px] flex-col justify-center md:mx-auto ${
      isRestarterFlow ? 'mt-0 h-[100vh]' : 'mt-[10px] h-auto md:mt-[100px]'
    }`}
  >
    {!isRestarterFlow && (
      <div className="mb-[20px] flex justify-between">
        <Link href="/">
          <Image src={logo} alt="Second Nature Logo" width={124} height={45} />
        </Link>
        <Link href="/">
          <Image src={cancelCross} alt="Cancel" width={48} height={47} />
        </Link>
      </div>
    )}
    {children}
  </div>
);
