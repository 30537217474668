import { useState, useRef, ElementRef, ChangeEvent, FormEvent } from 'react';

import { FormStateType } from '@nextTypes/login';

import { BackButton, BlueSubmitButton } from '@common/buttons';

import { TextInput, ErrorMessage, InputLabel } from '@common/formElements';

import ButtonContainer from '@components/Login/ButtonContainer';
import { H3 } from '@common/headline';
import { BodyRegular } from '@common/body';
import { ErrorTracking } from '@common/errorTracking';

type Props = {
  formState: FormStateType;
  setFormState: (arg0: FormStateType) => void;
};

const Page2 = ({ formState, setFormState }: Props): JSX.Element => {
  const [email, setEmail] = useState(formState.email);

  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(
    null,
  );
  const [isCheckingValidEmail, setIsCheckingValidEmail] = useState(false);

  const submitButton = useRef<null | ElementRef<typeof BlueSubmitButton>>(null);

  const onEmailFormChange = (event: ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  const checkValidEmail = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isCheckingValidEmail) {
      return;
    }

    if (email.length === 0) {
      if (submitButton.current) {
        submitButton.current.shakeButton();
      }
      setEmailErrorMessage('Please enter your email');
      return;
    }

    setIsCheckingValidEmail(true);
    setEmailErrorMessage(null);

    try {
      const response = await fetch(`/api/account-exists/${email}?apiVersion=2`);

      const { message } = await response.json();

      if (!response.ok) {
        if (submitButton.current) {
          submitButton.current.shakeButton();
        }
        setEmailErrorMessage(message);
        return;
      }

      setFormState({
        page: 3,
        email,
      });
    } catch (err) {
      ErrorTracking.track(err, {
        message: 'Error with account-exists call',
      });
      if (submitButton.current) {
        submitButton.current.shakeButton();
      }
      setEmailErrorMessage(
        'Error with call. Please check your internet connection or contact our support team',
      );
    } finally {
      setIsCheckingValidEmail(false);
    }
  };

  return (
    <>
      <div className="mb-8 text-center text-blue">
        <div className="mb-2">
          <H3>Enter your email</H3>
        </div>
        <BodyRegular>
          Enter the email address you used to sign up to Second Nature
        </BodyRegular>
      </div>
      <form onSubmit={checkValidEmail} noValidate>
        <InputLabel htmlFor="email">
          <strong>Email</strong>
        </InputLabel>
        <TextInput
          type="email"
          name="email"
          placeholder="jane.doe@example.com"
          value={email}
          onChange={onEmailFormChange}
          autoFocus
          required
        />

        <ErrorMessage errorMessage={emailErrorMessage} />

        <ButtonContainer>
          <BackButton onClick={() => setFormState({ ...formState, page: 1 })}>
            Back
          </BackButton>
          <BlueSubmitButton
            ref={submitButton}
            isSubmitting={isCheckingValidEmail}
          >
            Next
          </BlueSubmitButton>
        </ButtonContainer>
      </form>
    </>
  );
};

export default Page2;
