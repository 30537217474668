import { FormStateType } from '@nextTypes/login';

import { BackButton } from '@common/buttons';
import { H3 } from '@common/headline';
import { BodyRegular } from '@common/body';

type Props = {
  formState: FormStateType;
  setFormState: (arg0: FormStateType) => void;
};

const Page4 = ({ formState, setFormState }: Props): JSX.Element => (
  <>
    <div className="mb-8 text-center text-blue">
      <div className="mb-2">
        <H3>Magic link sent!</H3>
      </div>
      <BodyRegular>Please check your inbox</BodyRegular>
    </div>
    <div>
      <BackButton onClick={() => setFormState({ ...formState, page: 3 })}>
        Back
      </BackButton>
    </div>
  </>
);

export default Page4;
