import { useState } from 'react';

import { FormStateType } from '@nextTypes/login';

import {
  AppleSigninButton,
  BlueSubmitButton,
  FacebookSigninButton,
  Spinner,
} from '@common/buttons';

import { ErrorMessage } from '@common/formElements';

import UnderlinedLink from '@components/UnderlinedLink';
import GoogleSigninButton, {
  GoogleCredentialResponse,
} from '@components/GoogleSigninButton';

import { trackClickedGetQuiz } from '@nextUtils/trackingUtils';
import { BodyRegular } from '@common/body';
import { H3 } from '@common/headline';
import { ErrorTracking } from '@common/errorTracking';

type Props = {
  formState: FormStateType;
  setFormState: (formState: FormStateType) => void;
  title: string;
  noAccountPrompt?: string;
  signUpButtonText?: string;
  finishLoggingIn: () => Promise<void>;
};

type SocialSigninType = 'facebook' | 'google' | 'apple';

const Page1 = ({
  formState,
  setFormState,
  title,
  noAccountPrompt,
  signUpButtonText,
  finishLoggingIn,
}: Props): JSX.Element => {
  const [isSubmittingSocialSignin, setIsSubmittingSocialSignin] =
    useState<SocialSigninType | null>(null);

  const [registrationErrorText, setRegistrationErrorText] = useState<
    string | null
  >(null);

  const sendTokenToServer = async (
    signupType: SocialSigninType,
    token: string,
  ) => {
    const dataToSend: Record<string, string | boolean> = {
      attemptLoginOnly: true,
    };

    let signupUrl: string;

    if (signupType === 'facebook') {
      // eslint-disable-next-line camelcase
      dataToSend.access_token = token;
      signupUrl = '/api/auth/facebook/token?apiVersion=2';
    } else if (signupType === 'google') {
      // eslint-disable-next-line camelcase
      dataToSend.id_token = token;
      signupUrl = '/api/auth/google/oidc?apiVersion=2';
    } else if (signupType === 'apple') {
      // eslint-disable-next-line camelcase
      dataToSend.id_token = token;
      signupUrl = '/api/auth/apple-web/token?apiVersion=2';
    } else {
      throw new Error('Incorrect sign up flow');
    }

    setIsSubmittingSocialSignin(signupType);
    setRegistrationErrorText(null);

    try {
      const tokenResponse = await fetch(signupUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });
      if (!tokenResponse.ok) {
        const { message } = await tokenResponse.json();
        throw new Error(message);
      }
      await finishLoggingIn();
    } catch (err) {
      ErrorTracking.track(err);
      setRegistrationErrorText((err as Error).message);
    } finally {
      setIsSubmittingSocialSignin(null);
    }
  };

  const onClickFacebookSignin = () => {
    if (!window.FB) {
      setRegistrationErrorText(
        'The Facebook SDK has failed to load. Please reload the page and try again.',
      );
      return;
    }

    window.FB.login(
      response => {
        if (!response.authResponse) {
          const error = new Error(
            'Please press continue in the Facebook Dialog box.',
          );
          ErrorTracking.track(error);
          setRegistrationErrorText(error.message);
          return;
        }
        const { accessToken } = response.authResponse;
        sendTokenToServer('facebook', accessToken).catch(err => {
          ErrorTracking.track(err);
          setRegistrationErrorText((err as Error).message);
        });
      },
      {
        // n.b. public_profile is included by default, but is added here for posterity
        scope: 'public_profile,email',
      },
    );
  };

  const onClickAppleSignin = async () => {
    if (!window.AppleID) {
      setRegistrationErrorText(
        'The Apple SDK has failed to load. Please reload the page and try again.',
      );
      return;
    }

    try {
      const {
        // eslint-disable-next-line camelcase
        authorization: { id_token },
      } = await window.AppleID.auth.signIn();

      await sendTokenToServer('apple', id_token);
    } catch (err) {
      ErrorTracking.track(err);
      setRegistrationErrorText((err as Error).message);
    }
  };

  const handleGoogleCredentialResponse = async (
    response: GoogleCredentialResponse,
  ) => sendTokenToServer('google', response.credential);

  const onClickEmailSignin = () => {
    setFormState({ ...formState, page: 2 });
  };

  return (
    <>
      <div className="mb-8 text-center text-blue">
        <div className="mb-2">
          <H3>{title}</H3>
        </div>
        <BodyRegular>
          Use the option below you used to sign up to Second Nature
        </BodyRegular>
      </div>

      <div className="mb-[15px] flex justify-center">
        {isSubmittingSocialSignin === 'google' ? (
          <div className="relative flex w-[250px] cursor-not-allowed justify-center rounded bg-blue">
            <div className="relative h-[44px] w-10">
              <Spinner isShown />
            </div>
          </div>
        ) : (
          <GoogleSigninButton
            handleGoogleCredentialResponse={handleGoogleCredentialResponse}
          />
        )}
      </div>
      <div className="mb-[15px] flex justify-center">
        <AppleSigninButton
          onClick={onClickAppleSignin}
          isSubmitting={isSubmittingSocialSignin === 'apple'}
          isDisabled={isSubmittingSocialSignin !== null}
        />
      </div>
      <div className="mb-[15px] flex justify-center">
        <FacebookSigninButton
          onClick={onClickFacebookSignin}
          isSubmitting={isSubmittingSocialSignin === 'facebook'}
          isDisabled={isSubmittingSocialSignin !== null}
        />
      </div>

      <div className="my-8 text-center text-blue">
        <BodyRegular>Or you can use your email</BodyRegular>
      </div>

      <div className="mb-[15px] flex justify-center">
        <BlueSubmitButton
          onClick={onClickEmailSignin}
          width="250px"
          isDisabled={isSubmittingSocialSignin !== null}
          customStyle={{ fontSize: '14px' }}
        >
          Email
        </BlueSubmitButton>
      </div>
      <div className="mb-[15px] flex justify-center">
        <ErrorMessage errorMessage={registrationErrorText} />
      </div>
      <div className="flex justify-center text-blue">
        <BodyRegular>
          {noAccountPrompt && <span>{noAccountPrompt} </span>}
          {signUpButtonText && (
            <UnderlinedLink
              href="/get-plan"
              useLink
              onClick={() => {
                trackClickedGetQuiz({
                  ctaText: 'Continue',
                  location: 'Page1',
                });
              }}
            >
              {signUpButtonText}
            </UnderlinedLink>
          )}
        </BodyRegular>
      </div>
    </>
  );
};

export default Page1;
