import { useState, useRef, ElementRef } from 'react';
import { useRouter } from 'next/router';

import { FormStateType } from '@nextTypes/login';

import { BlueSubmitButton, BackButton } from '@common/buttons';
import { ErrorMessage } from '@common/formElements';

import ButtonContainer from '@components/Login/ButtonContainer';
import { H3 } from '@common/headline';
import { BodyRegular } from '@common/body';
import { ErrorTracking } from '@common/errorTracking';

type Props = {
  formState: FormStateType;
  setFormState: (arg0: FormStateType) => void;
  openMagicLinkInWebOnly?: boolean;
};

const Page3 = ({
  formState,
  setFormState,
  openMagicLinkInWebOnly = false,
}: Props): JSX.Element => {
  const [magicLinkErrorMessage, setMagicLinkErrorMessage] = useState<
    string | null
  >(null);
  const [isSendingMagicLink, setIsSendingMagicLink] = useState(false);

  const submitButton = useRef<null | ElementRef<typeof BlueSubmitButton>>(null);

  const {
    query: { nextPath },
  } = useRouter();

  const sendMagicLink = async () => {
    if (isSendingMagicLink) {
      return;
    }

    if (!formState.email) {
      if (submitButton.current) {
        submitButton.current.shakeButton();
      }
      setMagicLinkErrorMessage('No email to send magic link to');
      return;
    }

    setIsSendingMagicLink(true);
    setMagicLinkErrorMessage(null);

    try {
      let generateMagicLinkUrl = `/api/magic-link?email=${formState.email}`;

      if (openMagicLinkInWebOnly) {
        generateMagicLinkUrl += '&noApp=true';
      }

      if (nextPath) {
        generateMagicLinkUrl = `${generateMagicLinkUrl}&location=${nextPath}`;
      }
      const response = await fetch(generateMagicLinkUrl);

      if (response.ok) {
        setFormState({
          ...formState,
          page: 4,
        });
      } else {
        const responseMessage = await response.text();
        if (submitButton.current) {
          submitButton.current.shakeButton();
        }
        setMagicLinkErrorMessage(responseMessage);
      }
    } catch (err) {
      ErrorTracking.track(err, {
        message: 'Error with login call',
      });
      if (submitButton.current) {
        submitButton.current.shakeButton();
      }
      setMagicLinkErrorMessage(
        'Error with call. Please check your internet connection or contact our support team',
      );
    } finally {
      setIsSendingMagicLink(false);
    }
  };

  return (
    <>
      <div className="mb-8 text-center text-blue">
        <div className="mb-2">
          <H3>Want to skip the typing?</H3>
        </div>
        <BodyRegular>
          We can email you a magic link to sign you in automatically
        </BodyRegular>
      </div>
      <BlueSubmitButton
        onClick={sendMagicLink}
        isSubmitting={isSendingMagicLink}
        marginTop={20}
        buttonType="button"
        width="100%"
        ref={submitButton}
      >
        Email me a magic link
      </BlueSubmitButton>
      <BlueSubmitButton
        onClick={() => setFormState({ ...formState, page: 5 })}
        isInverted
        marginTop={20}
        buttonType="button"
        width="100%"
      >
        I{"'"}ll sign in with a password
      </BlueSubmitButton>

      <ErrorMessage errorMessage={magicLinkErrorMessage} />

      <ButtonContainer>
        <BackButton onClick={() => setFormState({ ...formState, page: 2 })}>
          Back
        </BackButton>
      </ButtonContainer>
    </>
  );
};

export default Page3;
